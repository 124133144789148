*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
    outline: none;
}

/* Hero Section Start */

.hero-section{
    background-image: url(../../../../public/assets/images/hero-background.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    border-bottom: 8px solid #cef43c;
}

.hero-section .hero-container{
    padding: 20px;
    margin-left: 40px;
    margin-right: 40px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
}

.hero-section .hero-container .hero-text{
    width: 80%;
}

.hero-section .hero-container h1{
    font-size: 3.3rem;
    margin-bottom: 12px;
    color: white;
}

.hero-section .hero-container .hero-text p{
    font-size: 1.4rem;
    margin-bottom: 12px;
    color: white;
}

.hero-section .hero-container a{
    padding: 1em 2.1em 1.1em;
    border-radius: 4px;
    margin: 8px;
    border: none;
    text-decoration: none;
    background-color: #cef43c;
    color: #2f7b0c;
    font-weight: 800;
    font-size: 0.85em;
    text-transform: uppercase;
    text-align: center;
    /* box-shadow: 0em -0.2rem 0em #f5f5dc inset; */
    transition: color .2    s ease-in-out;
}

.hero-section .hero-container a:hover{
    background-color: transparent;
    color: #fff;
    border: 3px solid #e2e273;
    cursor: pointer;
}

.hero-section .hero-container img{
    width: 100%;
    max-width: 450px;
    height: auto;

}

@media(max-width:848px){
    .hero-section .hero-container{
        flex-direction: column;
    }
    .hero-section .hero-container .hero-text{
        width: 100%;
        text-align: center;
    }
    .hero-section .hero-container h1{
        font-size: 3rem;
    }
}

@media(max-width:678px){
    .hero-section .hero-container h1{
        font-size: 2rem;
    }
}

/* Hero Section End */

/* About Section Start */

.about-section {
    padding:15px 9%;
    padding-bottom: 100px;
    border-bottom: 8px solid #cef43c;
}

.about-section .about-container h1{
    font-size: 30px;
    font-weight: 500;
    text-align: center;
    position: relative;
    margin-bottom: 60px;
    margin-top: 2rem;
}

.about-section .about-container h1:after{
    content: '';
    position: absolute;
    width: 100px;
    height: 4px;
    background-color: #d1f43e;
    bottom: -20px;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.about-section .about-container .box-container{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
    gap:15px;
}

.about-section .about-container .box-container .box{
    box-shadow: 0 5px 10px rgba(0,0,0,.2);
    border-radius: 5px;
    background: white;
    text-align: center;
    padding:30px 20px;
}

.about-section .about-container .box-container .box img{
    height: 80px;
}

.about-section .about-container .box-container .box h3{
    color:#444;
    font-size: 22px;
    padding:10px 0;
}

.about-section .about-container .box-container .box p{
    color:#777;
    font-size: 15px;
    line-height: 1.8;
}

.about-section .about-container .box-container .box .btn{
    margin-top: 10px;
    display: inline-block;
    background:#333;
    color:#fff;
    font-size: 17px;
    border-radius: 5px;
    padding: 8px 25px;
}

.about-section .about-container .box-container .box .btn:hover{
    letter-spacing: 1px;
}

.about-section .about-container .box-container .box:hover{
    box-shadow: 0 10px 15px rgba(0,0,0,.3);
    transform: scale(1.03);
}

@media (max-width:768px){
    .about-section .about-container{
        padding:20px;
    }
}

/* About Section End */

/* Category Section Start */

.category-section{
    padding:15px 9%;
    padding-bottom: 100px;
    background: #e2e273;
    border-bottom: 8px solid #cef43c;
}

.category-section .category-container h1{
    font-size: 30px;
    font-weight: 500;
    text-align: center;
    position: relative;
    margin-top: 2rem;
    margin-bottom: 4rem;
}

.category-section .category-container h1:after{
    content: '';
    position: absolute;
    width: 100px;
    height: 4px;
    background-color: #d1f43e;
    bottom: -20px;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.category-section .category-container{
    width: 100%;
    height: 100%;
    padding: 0 8%;
    
    /* background: wheat; */
    background: white;
    border-radius: 6px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.category-section .category-container .row{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 30px;
}

.category-section .category-container .category{
    text-align: center;
    padding: 25px 10px;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    background: transparent;
    transition: transform 0.5s, background 0.5s;
}

.category-section .category-container .category i{
    font-size: 40px;
    margin-bottom: 10px;
    color: #2f7b0c;
}

.category-section .category-container .category h2{
    font-weight: 600;
    margin-bottom: 8px;
}

.category-section .category-container .category:hover{
    background: #2f7b0c;
    color: #fff;
    transform: scale(1.05);
}

.category-section .category-container .category:hover i{
    color: #fff;
}

/* Category Section End */

/* After Event Start */

.after-event-section{
    min-height: 100vh;
    background: #fff;
    padding: 5rem 9% 5rem;
}

.after-event-row{
    /* display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem; */
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1rem;
}

/* @media (max-width: 768px){
    .after-event-row{
        flex-direction: column;
    }
} */

.after-event-section .after-event-heading h1{
    text-align: center;
}

.after-event-section .after-event-heading h1{
    font-size: 30px;
    font-weight: 500;
    text-align: center;
    position: relative;
    margin-top: 2rem;
    margin-bottom: 3rem;
}

.after-event-section .after-event-heading h1:after{
    content: '';
    position: absolute;
    width: 100px;
    height: 4px;
    background-color: #cef43c;
    bottom: -20px;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.after-event-section p{
    text-align: justify;
    font-size: 1rem;
    align-items: center;
}

/* After Event End */

/* Contact Start */

.contact-section{
    min-height: 100vh;
    width: 100%;
    background: #e2e273;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 8px solid #cef43c;
}

.contact-container{
    width: 80%;
    background: #fff;
    border-radius: 6px;
    padding: 30px 60px 40px 40px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.contact-section .contact-container h1{
    font-size: 30px;
    font-weight: 500;
    text-align: center;
    position: relative;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.contact-section .contact-container h1::after{
    content: '';
    position: absolute;
    width: 100px;
    height: 4px;
    background-color: #cef43c;
    bottom: -20px;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.contact-section .contact-container .content{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.contact-section .contact-container .content .left-side{
    width: 25%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    position: relative;
}

.contact-section .contact-container .content .left-side::before{
    content: '';
    position: absolute;
    height: 70%;
    width: 2px;
    right: -15px;
    top: 50%;
    transform: translateY(-50%);
    background: #afafb6;
}

.contact-section .contact-container .content .left-side .details{
    margin: 14px;
    text-align: center;
}

.contact-section .contact-container .content .left-side .details i{
    font-size: 30px;
    color: #2f7b0c;
    margin-bottom: 10px;
}

.contact-section .contact-container .content .left-side .details .topic{
    font-size: 18px;
    font-weight: 500;
}

.contact-section .contact-container .content .left-side .details .text-one a,
.contact-section .contact-container .content .left-side .details .text-two a{
    font-size: 14px;
    color: #000;
    text-decoration: none;
    transition: all 0.4s ease;
}

.contact-section .contact-container .content .left-side .details .text-one a:hover,
.contact-section .contact-container .content .left-side .details .text-two a:hover{
    opacity: 0.6;
}


.contact-section .contact-container .content .right-side{
    width: 75%;
    margin-left: 75px;
}


.contact-section .contact-container .content .right-side .topic-text{
    font-size: 23px;
    font-weight: 600;
    color: #2f7b0c;   
}

.contact-section .contact-container .content .right-side .input-box{
    height: 50px;
    width: 100%;
    margin: 12px 0;
}

.contact-section .contact-container .content .right-side .input-box input,
.contact-section .contact-container .content .right-side .input-box textarea{
    height: 100%;
    width: 100%;
    border: none;
    font-size: 16px;
    background: #f0f1f8;
    border-radius: 6px;
    padding: 0 15px;
}

.contact-section .contact-container .content .right-side .message-box{
    min-height: 110px;
}

.contact-section .contact-container .content .right-side .button{
    display: inline-block;
    margin-top: 12px;
}

.contact-section .contact-container .content .right-side .button input[type="submit"]{
    color: #fff;
    font-size: 18px;
    outline: none;
    border: none;
    padding: 8px 16px;
    border-radius: 6px;
    background: #2f7b0c;
    cursor: pointer;
    transition: all 0.3s ease;
}

.contact-section .contact-container .content .right-side .button input[type="submit"]:hover{
    background: #cef43c;
}

@media(max-width:952px){
    .contact-container{
        width: 90%;
        padding: 30px 35px 40px 35px;
    }
}

@media(max-width:820px){
    .contact-container{
        margin: 40px 0;
        height: 100%;
    }
    .contact-section .contact-container .content{
       flex-direction: column-reverse;
    }
    .contact-section .contact-container .content .left-side{
        width: 100%;
        flex-direction: row;
        margin-top: 40px;
        justify-content: center;
        flex-wrap: wrap;
    }
    .contact-section .contact-container .content .left-side::before{
        display: none;
    }
    .contact-section .contact-container .content .right-side{
        width: 100%;
        margin-left: 0;
    }
}

/* Contact End */

/* Logo Slider Section Start*/

.brand-logo-section{
    width: 100%;
    display: grid;
    place-items: center;
    font-family: 'Barlow', sans-serif;
    border-bottom: 8px solid #cef43c;
}

.brand-logo-section .brand-logo-container h1{
    font-size: 30px;
    font-weight: 500;
    text-align: center;
    position: relative;
    margin-top: 2rem;
    margin-bottom: 3rem;
}

.brand-logo-section .brand-logo-container h1:after{
    content: '';
    position: absolute;
    width: 100px;
    height: 4px;
    background-color: #cef43c;
    bottom: -20px;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.brand-logo-section .brand-logo-container{
    width: 90%;
    height: auto;
    text-align: center;
}

.brand-logo-section .brand-logo{
    width: 100%;
    height: auto;
    margin-bottom: 2rem;
}

.brand-logo-section .brand-logo ul{
    padding: 0;
    margin: 0;
}

.brand-logo-section .brand-logo ul li{
    list-style: none;
    display: inline-block;
    height: 100px;
    cursor: pointer;
    transition: .5s;
}

.brand-logo-section .brand-logo ul li img{
    width: 100%;
    height: 100%;
}

/* Logo Slider Section End*/