/* news card start */

:root {
    --container-color: #1a1e21;
    --second-color: #2f7b0c;
    --text-color: #172317;
    --bg-color: #fff;
}

.news-section a{
    text-decoration: none;
}

.news-section li{
    list-style: none;
}

.news-section img{
    width:100%;
}

.news-container{
    max-width: 1068px;
    margin: auto;
    width: 100%;
}

.post{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, auto));
    justify-content: center;
    gap: 1.5rem;
}

.post-box{
    background: var(--bg-color);
    box-shadow: 0 4px 14px hsl(355deg 25% 15% / 10%);
    padding: 15px;
    border-radius: 0.5rem;
}

.post-box a{
    color: var(--text-color);
}

.post-img{
    width: 100%;
    height: 200px;
    object-fit: cover;
    object-position: center;
    border-radius: 0.5rem;
}

.category{
    font-size: 0.9rem;
    font-weight: 500;
    text-transform: uppercase;
    color: var(--second-color);
}

.post-title{
    font-size: 1.3rem;
    font-weight: 600;
    color: var(--text-color);
    /* to remain title in 2 lines */
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.post-date{
    display: flex;
    font-size: 0.875rem;
    text-transform: uppercase;
    font-weight: 400;
    margin-top: 4px;
}

.post-decription{
    font-size: 0.9rem;
    line-height: 1.5rem;
    margin: 5px 0 10px;
    /* to remain title in 3 lines */
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.profile{
    display: flex;
    align-items: center;
    gap: 10px;
}

.news-section .profile-img{
    width: 35px;
    height: 35px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
    border: 2px solid var(--second-color);
}

/* news card end */
