*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.section-news-content{
    width: 100%;
    /* height: 500px; */
}

.container-news-content{
    max-width: 800px;
    margin: auto;
    /* height: 100%; */
}

.header-news-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 4rem !important;
}

.section-news-content .news-content-title{
    width: 90%;
    font-size: 1.6rem;
    color: #172317;
    text-align: center;
    margin-bottom: 1rem;
}

.section-news-content .news-content-image{
    width: 100%;
    height: 420px;
    object-fit: cover;
    object-position: center;
}

.news-content-paragraf{
    margin-top: 2rem;
}

.news-content-paragraf p{
    font-size: 1rem;
    line-height: 1.7rem;
    margin: 1rem 0;
    text-align: justify;
}

.news-content-paragraf a{
    
}
